import React from "react"
import SEO from "../components/seo"
import { Link } from "gatsby"
import CollectionItems from "../components/CollectionItems"
import CollectionTemplate from "../templates/CollectionTemplate"

const CollectionPage = () => {
  return (
    <CollectionTemplate collectionTitle="COLLECTION">
      <SEO
        title="Collection"
        keywords={[`rings`, `decollete`, `earrings`, `engagement`, `wedding`]}
        description={`Custom engagement rings and jewellery designs. Made with gold and conflict free, ethical precious stones (morganite, moissanite, sapphire, lab diamond, opal)`}
      />

      <div className="SEOContainer">
        {/* <div className="PicContain">
          <div className="SEOCollect" />
        </div> */}
        <div className="SEOText">
          Defined by memories and milestones, the Dianne Rowe Private Collection
          brings harmony to the polarising. Modern and vintage, unique and
          traditional, feminine and unconventional - juxtaposition has never
          looked so good. Designed in Perth, Western Australia, our collection
          is for the brave, bold, enlightened and empowered. You can rest
          assured that each breathtaking piece is masterfully handcrafted with
          ethically sourced precious gemstones and reclaimed gold.
        </div>
      </div>
      <div className="productnavbar">
        <Link className="linkspacer" to="/collection/rings">
          rings
        </Link>{" "}
        <div className="linkspacer">/</div>
        <Link className="linkspacer" to="/collection/decollete">
          decollete
        </Link>
        <div className="linkspacer">/</div>
        <Link className="linkspacer" to="/collection/earrings">
          earrings
        </Link>
        <div className="linkspacer">/</div>
        <Link className="linkspacer" to="/collection/homme">
          homme
        </Link>
        <div className="linkspacer">/</div>
        <Link className="linkspacer" to="/collection/bespoke">
          bespoke
        </Link>
        <div className="linkspacer">/</div>
        <Link className="linkspacer" to="/collection/bracelets">
          bracelets
        </Link>
      </div>
      <div id="scroll" className="storewrap">
        <CollectionItems />
      </div>
    </CollectionTemplate>
  )
}

export default CollectionPage
