import React, { useContext } from "react"
import { ProductsContext } from "./ProductsProvider"
import ProductThumbnail from "./ProductThumbnail"

const CollectionItems = () => {
  const { listProducts } = useContext(ProductsContext)
  const products = listProducts().filter((p) => {
    if (p.metadata.collection === "true") {
      return true
    }
    return false
  })

  return (
    <div className="productslayout">
      {products.map((product) => (
        <ProductThumbnail key={product.id} product={product} />
      ))}
    </div>
  )
}

export default CollectionItems
